import React, { forwardRef } from "react";

const Interface = forwardRef((props, ref) => {
    const { showAnimation, setShowAnimation, interfaceRef } = props;
    return (
        <div id="overlay-content" ref={ref}>
            <div className="dom-container">
                <div className="button-container">
                    <button
                        onClick={() => {
                            setShowAnimation && setShowAnimation(!showAnimation);

                        }}
                        className={"animationButton"}> Toggle Animation</button>

                </div>
            </div>

        </div>
    )
})

export default Interface;