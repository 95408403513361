import React, { useRef, useState, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Interactive } from "@react-three/xr";
import { useFrame, useThree } from "@react-three/fiber";

export default function Model(props) {
    const { position, showAnimation } = props;
    const [modelPos, setModelPos] = useState(position)
    const [rotation, setRotation] = useState([0,0,0])
    const group = useRef()
    const { nodes, materials,scene, animations } = useGLTF("/balloners-v13.glb");
    const { actions ,names} = useAnimations(animations, group);
  
    useEffect(() => {
        setModelPos(position)
    }, [position])

    useEffect(() => {
        if(showAnimation){
            actions && names[0] && actions[names[0]].reset().fadeIn(0.5).play();
        }else{
            actions && names[0] && actions[names[0]].reset().fadeOut(0.5).stop();
        }
      

    }, [showAnimation])



    return (
        <Interactive
            onMove={((e) => {
                let position = e.intersection.object.position.clone();
                let rotation = e.intersection.object.rotation.clone();
                console.log(e)
                // setModelPos({ ...modelPos, position })
                // setModelPos([0,0,0])
            })}
            // onSelectStart={((e)=>{
            //     console.log("onSelectStart")
            // })}
            // onSelectEnd={((e)=>{
            //     console.log("onSelectEnd")
            // })}
            // onSelect ={((e)=>{
            //     console.log("onSelect")
            // })}

            // onSqueezeStart={((e)=>{
            //     console.log("onSqueezeStart")
            // })}
         
            // onSqueezeEnd={((e)=>{
            //     console.log("onSqueezeEnd")
            // })}
            // onSqueeze={((e)=>{
            //     console.log("onSqueeze")
            // })}


            >
                
                <primitive object={scene}
                ref={group} dispose={null} position={modelPos} rotation={rotation} />

            {/* <group ref={group} dispose={null} position={modelPos} rotation={rotation} >
                <group name="Scene">
                    <mesh
                        name="Cube"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube.geometry}
                        material={materials["Material.001"]}
                        position={[0.031, 3.182, -0.138]}
                        scale={[0.983, 0.932, 0.983]}
                    />
                    <mesh
                        name="Cube003"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube003.geometry}
                        material={materials["Material.036"]}
                        position={[1.087, 3.875, -0.468]}
                        rotation={[0.004, -0.097, 0]}
                        scale={[0.594, 1.25, 0.156]}
                    />
                    <mesh
                        name="Cube004"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube004.geometry}
                        material={materials["Material.035"]}
                        position={[-1.44, 3.737, -0.451]}
                        rotation={[0.004, 0.058, 0]}
                        scale={[0.594, 1.25, 0.156]}
                    />
                    <mesh
                        name="Cube005"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube005.geometry}
                        material={materials["Material.017"]}
                        position={[0.037, 2.984, 1.125]}
                        rotation={[-0.253, 0, 0]}
                        scale={[0.196, 0.196, 0.042]}
                    />
                    <group
                        name="Cube006"
                        position={[-0.033, 3.209, 0.607]}
                        rotation={[-0.222, 0.588, -0.091]}
                        scale={[0.38, 0.211, 0.125]}
                    >
                        <mesh
                            name="Cube007_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube007_1.geometry}
                            material={materials["Material.027"]}
                        />
                        <mesh
                            name="Cube007_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube007_2.geometry}
                            material={materials["Material.041"]}
                        />
                    </group>
                    <group
                        name="Cube007"
                        position={[-0.024, 3.365, 0.599]}
                        rotation={[-0.334, 0.624, 0.52]}
                        scale={[0.373, 0.064, 0.146]}
                    >
                        <mesh
                            name="Cube009_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube009_1.geometry}
                            material={materials["Material.029"]}
                        />
                        <mesh
                            name="Cube009_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube009_2.geometry}
                            material={materials["Material.021"]}
                        />
                    </group>
                    <group
                        name="Cube012"
                        position={[-0.338, 0.432, 0.069]}
                        rotation={[0, -0.553, 0]}
                        scale={[0.125, 0.133, 0.23]}
                    >
                        <mesh
                            name="Cube003_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube003_1.geometry}
                            material={materials["Material.007"]}
                        />
                        <mesh
                            name="Cube003_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube003_2.geometry}
                            material={materials["Material.005"]}
                        />
                        <mesh
                            name="Cube003_3"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube003_3.geometry}
                            material={materials["Material.006"]}
                        />
                    </group>
                    <group
                        name="Cube013"
                        position={[0.349, 0.43, 0.069]}
                        rotation={[0, 0.506, 0]}
                        scale={[0.118, 0.125, 0.216]}
                    >
                        <mesh
                            name="Cube013_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube013_1.geometry}
                            material={materials["Material.007"]}
                        />
                        <mesh
                            name="Cube013_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube013_2.geometry}
                            material={materials["Material.014"]}
                        />
                        <mesh
                            name="Cube013_3"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube013_3.geometry}
                            material={materials["Material.013"]}
                        />
                    </group>
                    <mesh
                        name="Cube014"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube014.geometry}
                        material={materials["Material.039"]}
                        position={[-0.005, 2.488, 0.895]}
                        rotation={[0, 0, 0.062]}
                        scale={[0.126, 0.201, 0.034]}
                    />
                    <group
                        name="Cube018"
                        position={[-0.025, 1.001, 0]}
                        scale={[0.572, 0.228, 0.33]}
                    >
                        <mesh
                            name="Cube018_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube018_1.geometry}
                            material={materials["Material.010"]}
                        />
                        <mesh
                            name="Cube018_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube018_2.geometry}
                            material={materials["Material.008"]}
                        />
                    </group>
                    <mesh
                        name="Sphere002"
                        castShadow
                        receiveShadow
                        geometry={nodes.Sphere002.geometry}
                        material={materials["Material.003"]}
                        position={[-0.191, 4.378, 0.1]}
                        rotation={[1.187, 0.167, 0.001]}
                        scale={[0.018, 0.015, 0.015]}
                    />
                    <group
                        name="Cube011"
                        position={[-0.026, 1.53, 0.001]}
                        scale={[0.789, 0.685, 0.393]}
                    >
                        <mesh
                            name="Cube014_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube014_1.geometry}
                            material={materials["Material.009"]}
                        />
                        <mesh
                            name="Cube014_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube014_2.geometry}
                            material={materials["Material.016"]}
                        />
                    </group>
                    <mesh
                        name="Sphere9001"
                        castShadow
                        receiveShadow
                        geometry={nodes.Sphere9001.geometry}
                        material={materials["BasketballColor2_Mat.002"]}
                        position={[1.354, 2.849, 0.523]}
                        rotation={[3.114, 0, 0.005]}
                        scale={0.01}
                    />
                    <mesh
                        name="Cylinder010"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder010.geometry}
                        material={materials["Material.004"]}
                        position={[2.373, 1.835, 0.001]}
                        rotation={[-0.004, -0.003, -0.348]}
                        scale={[0.028, 1.597, 0.018]}
                    />
                    <mesh
                        name="Cylinder011"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder011.geometry}
                        material={materials["Material.004"]}
                        position={[2.48, 2.101, 0.001]}
                        rotation={[0, 0, -0.306]}
                        scale={[0.096, 0.172, 0.039]}
                    />
                    <mesh
                        name="Cylinder012"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder012.geometry}
                        material={nodes.Cylinder012.material}
                        position={[2.516, 2.004, 0.021]}
                        rotation={[1.663, -0.035, -0.343]}
                        scale={[0.019, 0.003, 0.019]}
                    />
                    <mesh
                        name="Cylinder013"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder013.geometry}
                        material={nodes.Cylinder013.material}
                        position={[2.489, 1.93, 0.023]}
                        rotation={[1.663, -0.035, -0.343]}
                        scale={[0.019, 0.003, 0.019]}
                    />
                    <mesh
                        name="Cylinder014"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder014.geometry}
                        material={nodes.Cylinder014.material}
                        position={[2.516, 2.004, -0.028]}
                        rotation={[1.473, -0.008, -3.038]}
                        scale={[0.019, 0.003, 0.019]}
                    />
                    <mesh
                        name="Cylinder015"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder015.geometry}
                        material={nodes.Cylinder015.material}
                        position={[2.488, 1.931, -0.028]}
                        rotation={[1.473, -0.008, -3.038]}
                        scale={[0.019, 0.003, 0.019]}
                    />
                    <mesh
                        name="BezierCurve"
                        castShadow
                        receiveShadow
                        geometry={nodes.BezierCurve.geometry}
                        material={materials["Material.011"]}
                        position={[1.752, 1.506, 0.001]}
                        rotation={[0, 0, -1.592]}
                        scale={1.738}
                    />
                    <group
                        name="Cube010"
                        position={[3.637, 3.343, 0.001]}
                        rotation={[0, 0, -0.335]}
                        scale={[0.659, 1.738, 0.011]}
                    >
                        <mesh
                            name="Cube012_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube012_1.geometry}
                            material={materials["Material.012"]}
                        />
                        <mesh
                            name="Cube012_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube012_2.geometry}
                            material={materials["Material.018"]}
                        />
                    </group>
                    <group name="Armature" position={[-0.055, 1.213, 0]} scale={0.593}>
                        <skinnedMesh
                            name="Cube002"
                            geometry={nodes.Cube002.geometry}
                            material={materials["Material.001"]}
                            skeleton={nodes.Cube002.skeleton}
                        />
                        <skinnedMesh
                            name="Cube008"
                            geometry={nodes.Cube008.geometry}
                            material={materials["Material.001"]}
                            skeleton={nodes.Cube008.skeleton}
                        />
                        <primitive object={nodes.Bone} />
                        <primitive object={nodes.Bone002} />
                        <primitive object={nodes.Bone003} />
                    </group>
                    <mesh
                        name="Cylinder008"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder008.geometry}
                        material={materials["Material.002"]}
                        position={[0.981, 1.918, 0.415]}
                        rotation={[0.456, -0.501, -0.743]}
                        scale={[0.106, 0.064, 0.121]}
                    />
                    <mesh
                        name="Cylinder009"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder009.geometry}
                        material={materials["Material.015"]}
                        position={[-0.977, 2.099, 0.41]}
                        rotation={[-2.849, -0.214, 2.508]}
                        scale={[0.128, 0.08, 0.134]}
                    />
                    <mesh
                        name="Text"
                        castShadow
                        receiveShadow
                        geometry={nodes.Text.geometry}
                        material={materials.Material}
                        position={[-0.13, 4.129, 0.83]}
                        rotation={[Math.PI / 2, 0.171, 0]}
                        scale={[0.483, 0.628, 0.504]}
                    />
                    <mesh
                        name="Cube009"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube009.geometry}
                        material={materials.Material}
                        position={[-0.481, 4.485, 0.902]}
                        rotation={[0, 0, 0.048]}
                        scale={[0.204, 0.038, 0.076]}
                    />
                    <mesh
                        name="Cylinder"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder.geometry}
                        material={materials["Material.040"]}
                        position={[0.306, 2.889, 1.08]}
                        rotation={[1.341, 0.174, -0.378]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                    <mesh
                        name="Cylinder001"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder001.geometry}
                        material={materials["Material.042"]}
                        position={[0.375, 2.863, 1.052]}
                        rotation={[1.415, 0.208, -0.507]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                    <mesh
                        name="Cylinder002"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder002.geometry}
                        material={materials["Material.043"]}
                        position={[0.293, 2.824, 1.096]}
                        rotation={[1.654, 0.179, -0.362]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                    <mesh
                        name="Cylinder003"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder003.geometry}
                        material={materials["Material.044"]}
                        position={[0.346, 2.805, 1.072]}
                        rotation={[1.605, 0.132, -0.448]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                    <mesh
                        name="Cylinder007"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder007.geometry}
                        material={materials["Material.048"]}
                        position={[-0.427, 2.804, 1.058]}
                        rotation={[1.075, 0.204, 0.521]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                    <mesh
                        name="Cylinder006"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder006.geometry}
                        material={materials["Material.047"]}
                        position={[-0.349, 2.808, 1.098]}
                        rotation={[1.244, 0.185, 0.416]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                    <mesh
                        name="Cylinder005"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder005.geometry}
                        material={materials["Material.046"]}
                        position={[-0.418, 2.744, 1.066]}
                        rotation={[1.275, 0.498, 0.633]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                    <mesh
                        name="Cylinder004"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder004.geometry}
                        material={materials["Material.045"]}
                        position={[-0.357, 2.748, 1.095]}
                        rotation={[1.447, 0.286, 0.468]}
                        scale={[0.016, 0.003, 0.016]}
                    />
                </group>
            </group> */}
        </Interactive>
    );
}

useGLTF.preload("/balloners-v13.glb");