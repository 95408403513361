import Test from "./components/3dViewer"
import "./App.css";
function App() {
  return (
    <div style={{height:"100vh",width:"100vw"}}>
      
      <Test/>
    </div>
  );
}

export default App;
