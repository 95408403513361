import React, { useEffect, useState, Suspense, useRef, useCallback } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import {
    XR,
    ARButton,
    Controllers
} from "@react-three/xr";

import XrHitCube from "./XrHitCube"
import Interface from "./interface";



const App = (props) => {
    const [showAnimation, setShowAnimation] = useState(true);

    const [overlayContent, setOverlayContent] = useState(null);
    let interfaceRef = useCallback((node) => {
      if (node !== null) {
        setOverlayContent(node);
      }
    });


    const initialProps = {
        ...props,
        showAnimation, setShowAnimation,
    }
    return (
        <div style={{ width: "100vw", height: "100vh" }}>
            <ARButton
                className="ar-button"
                sessionInit={{
                    requiredFeatures: ["hit-test"],
                    optionalFeatures: ["dom-overlay", 'dom-overlay-for-handheld-ar'],
                    domOverlay: { root: overlayContent },
                }} />

            <Canvas camera={{ position: [0, 3, 10], fov: 100 }}>
                <OrbitControls />
                <ambientLight intensity={3} />
                <directionalLight position={[0, 0, 5]} color="white" />
                <pointLight position={[0, 0, 5]} />

                <XR>
                    <XrHitCube {...initialProps} />
                    <Controllers />
                </XR>

            </Canvas>
            <Interface ref={interfaceRef} {...initialProps} />
        </div>
    );
};

// Function to generate random number
function randomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function downloadFile(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export default App;
