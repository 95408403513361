import { useRef, useState,useEffect } from "react"
import { OrbitControls } from "@react-three/drei"
import { Interactive, useHitTest, useXR } from "@react-three/xr";
import Balloners from "./model";
import { useThree } from "@react-three/fiber";


const XrHitCube = (props) => {
    const reticleRef = useRef();
    const [models, setModels] = useState({
        id: Date.now(),
        position: { x: 0, y: -5, z: -4 }
    });
    const { camera } = useThree();
    const { isPresenting } = useXR()
    // useThree(({ camera }) => {

    //     camera.position.z = 10;
    // })
    useHitTest((hitMatrix, hit) => {
        hitMatrix.decompose(
            reticleRef.current.position,
            reticleRef.current.quaternion,
            reticleRef.current.scale
        )
        reticleRef.current.rotation.set(-Math.PI / 2, 0, 0);
    });


    // useEffect(() => {
    //     const handleCameraMove = () => {
    //       console.log('Camera Position:', camera.position);
    //     };

    //     window.addEventListener('mousemove', handleCameraMove);

    //     return () => {
    //       window.removeEventListener('mousemove', handleCameraMove);
    //     };
    //   }, [camera]);


    const placeObject = (e) => {
        let position = e.intersection.object.position.clone();
        let id = Date.now();
        setModels({ ...models, position, id })

    }


    const initialProps = {
        ...props,
        models, setModels,
    }

    return (
        <>
            {!isPresenting && <Balloners position={[0, 0, 0]} {...initialProps} />}
            {isPresenting && models && <Balloners position={models.position}  {...initialProps} />}

            {isPresenting && (
                <Interactive onSelect={placeObject}>

                    <mesh ref={reticleRef} rotation-x={Math.PI / 2}>
                        <ringGeometry args={[0.1, 0.25, 32]} />
                        <meshStandardMaterial color={"white"} />
                    </mesh>
                </Interactive>
            )}

        </>
    )
}

export default XrHitCube;